<template>
  <div id="med-time-list">
    <!-- dialog -->

    <delete-confirmation
      v-model="isDeleteDialogActive"
      :selected-item="selectedItem"
      @delete-confirmation="submitDelete"
      @refetch-data="fetchData"
    ></delete-confirmation>

    <med-time-add-edit
      v-model="isMedTimeFormActive"
      :is-med-time-form-active="isMedTimeFormActive"
      :is-edit="isEdit"
      :site-med-time-d-t-o="selectedItem"
      @update-dialog="updateDialog"
      @refetch-data="fetchData"
    ></med-time-add-edit>

    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchTime"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="allergy-search me-3 mb-4"
        >
        </v-text-field>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            v-model="isMedTimeFormActive"
            color="primary"
            @click="updateDialog(null), isEdit = true"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Time</span>
          </v-btn>
        </div>

        <v-spacer></v-spacer>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedTime"
        :headers="medTimeTableColumns"
        :items="medTimeList"
        :options.sync="options"
        :search="searchTime"
        :loading="loading"
        group-by="isActive"
        :group-desc="true"
      >
        <template #[`item.isActive`]="{item}">
          {{ readStatus(item.isActive) }}
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = false, isMedTimeFormActive = !isMedTimeFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = true, isMedTimeFormActive = !isMedTimeFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="selectItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span>{{ item.isActive ? 'Disable' : 'Activate' }}</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isDeleteDialogActive = !isDeleteDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import medicationService from '@/services/MedicationService'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import useMedicationList from '@/store/useMedicationService'
import CommonList from '@/store/CommonService'
import MedTimeAddEdit from './MedTimeAddEdit.vue'

export default {
  components: { DeleteConfirmation, MedTimeAddEdit },
  setup() {
    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    const {
      medTimeList,
      medTimeTableColumns,
      searchTime,
      statusFilter,
      loading,
      options,
      selectedTime,

      fetchSiteMedTimes,
    } = useMedicationList()

    const { readStatus, formatDate } = CommonList()

    const isEdit = ref(false)
    const selectedItem = ref()
    const isDeleteDialogActive = ref(false)
    const isMedTimeFormActive = ref(false)

    return {
      medTimeList,
      medTimeTableColumns,
      searchTime,
      statusFilter,
      loading,
      options,
      isMedTimeFormActive,
      selectedItem,
      isEdit,
      isDeleteDialogActive,
      selectedTime,
      readStatus,
      formatDate,

      fetchSiteMedTimes,
      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },
  mounted() {
    this.fetchSiteMedTimes()
    //console.log(this.medTimeList)
  },
  methods: {
    fetchData() {
      this.fetchSiteMedTimes()
      this.$watch('medTimeList', () => {
        //console.log(this.medTimeList)
      })
    },
    submitDelete(med) {
      //console.log(med)
    },
    updateDialog(status) {
      //console.log('Status', status)
      //console.log('Dialog', this.isMedTimeFormActive)
      if (status == null) {
        this.selectedItem = {}
      }
      this.isMedTimeFormActive = !this.isMedTimeFormActive
      //console.log('Selected Item', this.selectedItem)
    },
  },
}
</script>
