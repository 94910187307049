<template>
  <div>
    <div>
      <v-row justify="center">
        <v-dialog
          v-model="isMedTimeFormActive"
          persistent
          max-width="50%"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Med Time Details</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="SiteMedTimeForm"
              >
                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="siteMedTimeDTO.time"
                      type="number"
                      label="Med Pass Time"
                      hint="800"
                      :readonly="!isEdit"
                      :rules="[required]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                  >
                    <v-text-field
                      v-model="siteMedTimeDTO.order"
                      type="number"
                      label="Time Order"
                      hint="1-24"
                      :readonly="!isEdit"
                      :rules="[required]"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      lg="6"
                    >
                      <v-switch
                        v-model="siteMedTimeDTO.isActive"
                        color="primary"
                        :value="siteMedTimeDTO.isActive = true"
                        :label="siteMedTimeDTO.isActive ? 'Active' : 'Inactive'"
                        :readonly="!isEdit"
                      >
                      </v-switch>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        color="error darken-1"
                        type="reset"
                        text
                        @click="closeForm()"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        v-if="isEdit"
                        color="primary darken-1"
                        text
                        @click="submitForm(siteMedTimeDTO)"
                      >
                        Save
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import medicationService from '@/services/MedicationService'
import useMedicationList from '@/store/useMedicationService'

export default {
  model: {
    prop: 'isMedTimeFormActive',
    event: 'update:is-med-time-form-active',
  },
  props: {
    isMedTimeFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    siteMedTimeDTO: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    //console.log(props.siteMedTimeDTO)

    const SiteMedTimeForm = ref(null)
    const { postSiteMedTime } = useMedicationList()
    const menuID = ref(false)
    const menuLO = ref(false)
    const menuref = ref(null)

    const validate = siteMedTimeDTO => {
      if (SiteMedTimeForm.value.validate(siteMedTimeDTO)) {
        return true
      }

      return false
    }

    const save = d => {
      menuref.value.save(d)
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update-dialog')
    }

    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    return {
      SiteMedTimeForm,
      validate,
      closeForm,
      refreshParent,

      postSiteMedTime,

      required,
      menuID,
      menuLO,
      menuref,
      save,

      icons: {
        mdiClose,
      },
    }
  },
  methods: {
    submitForm() {
      if (this.validate(this.siteMedTimeDTO)) {
        this.postSiteMedTime(this.siteMedTimeDTO)

        //this is a really bad way to do it, but I couldn't come up with a better way
        setTimeout(() => {
          this.closeForm()
          this.refreshParent()
        }, 150)
      } else {
        this.closeForm()
        this.refreshParent()
      }
    },
    updateMedTimes(medTime) {
      this.siteMedTimeDTO.time = medTime
      console.log(medTime)
    },
  },
}
</script>
