import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"50%"},model:{value:(_vm.isMedTimeFormActive),callback:function ($$v) {_vm.isMedTimeFormActive=$$v},expression:"isMedTimeFormActive"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Med Time Details")])]),_c(VCardText,[_c(VForm,{ref:"SiteMedTimeForm"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VTextField,{attrs:{"type":"number","label":"Med Pass Time","hint":"800","readonly":!_vm.isEdit,"rules":[_vm.required]},model:{value:(_vm.siteMedTimeDTO.time),callback:function ($$v) {_vm.$set(_vm.siteMedTimeDTO, "time", $$v)},expression:"siteMedTimeDTO.time"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"4"}},[_c(VTextField,{attrs:{"type":"number","label":"Time Order","hint":"1-24","readonly":!_vm.isEdit,"rules":[_vm.required]},model:{value:(_vm.siteMedTimeDTO.order),callback:function ($$v) {_vm.$set(_vm.siteMedTimeDTO, "order", $$v)},expression:"siteMedTimeDTO.order"}})],1)],1),_c(VRow,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VSwitch,{attrs:{"color":"primary","value":_vm.siteMedTimeDTO.isActive = true,"label":_vm.siteMedTimeDTO.isActive ? 'Active' : 'Inactive',"readonly":!_vm.isEdit},model:{value:(_vm.siteMedTimeDTO.isActive),callback:function ($$v) {_vm.$set(_vm.siteMedTimeDTO, "isActive", $$v)},expression:"siteMedTimeDTO.isActive"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"error darken-1","type":"reset","text":""},on:{"click":function($event){return _vm.closeForm()}}},[_vm._v(" Close ")]),(_vm.isEdit)?_c(VBtn,{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.submitForm(_vm.siteMedTimeDTO)}}},[_vm._v(" Save ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }